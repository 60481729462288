<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            
            <div style="width:600px;height:500px;background:#fff;margin:30px; 0 0 100px;">
                <el-form ref="form" label-width="80px">
                <el-form-item label="原密码">
                    <el-input v-model="saveData.original_password" type="password" placeholder="请输入原密码"></el-input>
                </el-form-item>
                 <el-form-item label="新密码">
                    <el-input v-model="saveData.new_password" type="password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                 <el-form-item label="确认密码">
                    <el-input v-model="saveData.confirm_password" type="password" placeholder="请确认新密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save">修改</el-button>
                </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
    props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            allSum:0,
            req:{
                limit:30,
                page:1,
                keywords:''
            },
            saveData:{
                original_password:'',
                new_password:'',
                confirm_password:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        save(){
            if(this.saveData.new_password !== this.saveData.confirm_password){
                this.$message({
                    type:'error',
                    message:'两次输入的密码不一致！'
                });
                return;
            }
            for(let i in this.saveData){
                this.saveData[i] = tools.encrypt(this.saveData[i]);
            }

            apis.changePass(this.saveData).then(res=>{
                tools.msg(res,this,1);
                //修改成功后重新登录
                tools.tui(this);
            }).catch(err=>{
                tools.err(err,this);
            });
        },
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        enable(id,item){
            if(item.enable_status == '开启'){
                item.enable_status = '禁用';
            }else{
                item.enable_status = '开启';
            }
            apis.supplierCateSet({id:id}).then(res=>{
                tools.msg(res,this,1);
            }).catch(err=>{
                tools.err(err,this);
            });
        },
        getList(){
            apis.supplierCateList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.tableData.forEach((item,key)=>{
                    for(let i in item){
                        if(i === 'beginning_balance' || i === 'balance'){
                            item[i] = tools.setPrice(item[i]);
                        }
                    }
                });
                this.allSum = res.count;
                this.allSum = tools.setPrice(res.count);
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定要删除吗?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.supplierCateDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        console.log(apis);
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.all_div{
    border-width: 0px;
    width: 96%;
    height: 140px;
    background: inherit;
    background-color: rgba(0, 110, 255, 0.0980392156862745);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 110, 255, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #006EFF;
    text-align: center;
}
</style>